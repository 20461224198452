<template>
  <div class="oferta">
    <b-card
      no-body
      class="overflow-hidden mb-1"
      ><img
        v-if="itemOferta.Capa"
        :src="corrigeMarca(itemOferta.Capa)"
        class="capa my-2"
      />
      <b-row no-gutters>
        <b-col cols="3">
          <img
            :src="corrigeMarca(itemOferta.Marca)"
            class="rounded-0 mb-3"
            width="100%"
          />
        </b-col>
        <b-col>
          <div class="contInterno ml-3 mb-3">
            <h4 class="mt-4 mb-3">{{ itemOferta.Nome }}</h4>
            <span class="mt-3">{{ itemOferta.CategoriaNome }}</span>
            <a
              target="_blank"
              :href="!!itemOferta.Insta ? 'https://instagram.com/' + itemOferta.Insta : 'https://instagram.com/clubecerto'"
              ><b-button class="btn-star">
                <i
                  class="fa fa-instagram"
                  aria-hidden="true"
                ></i> </b-button
            ></a>
            <b-button
              class="btn-star"
              :style="`background-color:${temaCor.CorBackgroundCabecalho};color:${temaCor.CorItensCabecalho}`"
              @click="adicionarFavoritos(itemOferta)"
            >
              <i class="fa fa-star"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="itemOferta.Sobre"
      no-body
      class="overflow-hidden mb-1"
    >
      <b-row no-gutters>
        <b-col cols="1">
          <i
            class="fa fa-info-circle icone"
            :style="`color:${temaCor.CorBackgroundCabecalho}`"
          ></i>
        </b-col>
        <b-col>
          <div class="contInterno ml-3">
            <div class="mt-3"><b>Descrição</b></div>
            <div
              class="mt-3 mb-3"
              v-html="itemOferta.Sobre"
            ></div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="itemOferta.Telefone"
      no-body
      class="overflow-hidden mb-1"
    >
      <b-row no-gutters>
        <b-col cols="1">
          <i
            class="fa fa-phone icone"
            :style="`color:${temaCor.CorBackgroundCabecalho}`"
          ></i>
        </b-col>
        <b-col>
          <div class="contInterno ml-3">
            <div class="mt-3"><b>Telefone</b></div>
            <div class="mt-3 mb-3">
              <a :href="'tel:' + itemOferta.Telefone">{{ itemOferta.Telefone }}</a>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="itemOferta.WhatsApp"
      no-body
      class="overflow-hidden mb-1"
    >
      <b-row no-gutters>
        <b-col cols="1">
          <i
            class="fa fa-whatsapp icone"
            :style="`color:${temaCor.CorBackgroundCabecalho}`"
          ></i>
        </b-col>
        <b-col>
          <div class="contInterno ml-3">
            <div class="mt-3"><b>WhatsApp</b></div>
            <div class="mt-3 mb-3">
              <a
                target="_blank"
                :href="'http://wa.me/55' + formataWhats(itemOferta.Telefone)"
                >{{ itemOferta.WhatsApp }}</a
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="itemOferta.Voucher"
      no-body
      class="overflow-hidden mb-1"
    >
      <b-row no-gutters>
        <b-col cols="1">
          <i
            class="fa fa-ticket icone"
            :style="`color:${temaCor.CorBackgroundCabecalho}`"
          ></i>
        </b-col>
        <b-col>
          <div class="contInterno ml-3">
            <div class="mt-3"><b>Cupom</b></div>
            <div class="mt-3 mb-3">{{ itemOferta.Voucher }}</div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="itemOferta.Linksite"
      no-body
      class="overflow-hidden mb-1"
    >
      <b-row no-gutters>
        <b-col cols="1">
          <i
            class="fa fa-globe icone"
            :style="`color:${temaCor.CorBackgroundCabecalho}`"
          ></i>
        </b-col>
        <b-col>
          <div class="contInterno ml-3">
            <div class="mt-3"><b>Site</b></div>
            <div class="mt-3 mb-3">
              <a
                :href="returnLink(itemOferta.Linksite)"
                target="_blank"
                >{{ itemOferta.Linkdescricao }}</a
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="itemOferta.Beneficios.length > 0"
      no-body
      class="overflow-hidden mb-1"
    >
      <b-row no-gutters>
        <b-col cols="1">
          <i
            class="fa fa-percent icone"
            :style="`color:${temaCor.CorBackgroundCabecalho}`"
          ></i>
        </b-col>
        <b-col>
          <div class="contInterno ml-3">
            <div class="mt-3"><b>Desconto(s)</b></div>
            <div class="mt-3 mb-3">
              <div
                v-for="item in itemOferta.Beneficios"
                :key="item.id"
              >
                <b>{{ item.desconto }}</b>
                <p v-html="item.regra"></p>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="itemOferta.Enderecos.length > 0"
      no-body
      class="overflow-hidden mb-1"
    >
      <b-row no-gutters>
        <b-col cols="1">
          <i
            class="fa fa-map-marker icone"
            :style="`color:${temaCor.CorBackgroundCabecalho}`"
          ></i>
        </b-col>
        <b-col>
          <div class="contInterno ml-3">
            <div class="mt-3"><b>Endereço(s)</b></div>
            <div class="mt-3 mb-3">
              <div
                v-for="item in itemOferta.Enderecos"
                :key="item.id"
              >
                <b>{{ item.titulo }}</b>
                <p>{{ item.endereco }}</p>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="itemOferta.Regras"
      no-body
      class="overflow-hidden mb-1"
    >
      <b-row no-gutters>
        <b-col cols="1">
          <i
            class="fa fa-exclamation-triangle icone"
            :style="`color:${temaCor.CorBackgroundCabecalho}`"
          ></i>
        </b-col>
        <b-col>
          <div class="contInterno ml-3">
            <div class="mt-3"><b>Regras</b></div>
            <div
              class="mt-3 mb-3"
              v-html="itemOferta.Regras"
            ></div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from "jsonwebtoken";
export default {
  name: "DashBoard",
  props: {
    itemOferta: Object,
    itemClick: Object,
  },
  data() {
    return {
      width: { width: "0px" },
      temaCor: {
        CorBackgroundCabecalho: null,
        CorItensCabecalho: null,
      },
    };
  },
  methods: {
    returnLink(link) {
      if (link) {
        if (link.indexOf("?") >= 0) return link + "&from=app-web-view";
        else return link + "?from=app-web-view";
      }
      return "";
    },
    formataWhats(telefone) {
      if (telefone) {
        return telefone.replace(/[^0-9]/g, "");
      }
      return "";
    },
    formataNome(nome) {
      var aux = "";
      if (nome) aux = nome.split(" ")[0];
      return aux;
    },
    openMenu() {
      this.width.width = "90%";
    },
    closeMenu() {
      this.width.width = "0px";
      this.$emit("closeMenuLateral", true);
    },
    adicionarFavoritos(item) {
      var favoritos = localStorage.getItem("favoritos");
      if (favoritos) {
        favoritos = JSON.parse(favoritos);
      } else favoritos = [];
      var newObj = { ...this.itemClick };
      if (favoritos.length == 0) {
        favoritos.push(newObj);
        this.$bvToast.toast("Estabelecimento adicionado aos Favoritos ", {
          title: "Atenção!",
          solid: true,
          variant: "success",
          toaster: "b-toaster-top-full",
          appendToast: false,
          autoHideDelay: 2500,
        });
      } else {
        var aux = favoritos.find((x) => x.Codigo == newObj.Codigo);
        if (!aux) {
          favoritos.push(newObj);
          this.$bvToast.toast("Estabelecimento adicionado aos Favoritos ", {
            title: "Atenção!",
            solid: true,
            variant: "success",
            toaster: "b-toaster-top-full",
            appendToast: false,
            autoHideDelay: 2500,
          });
        } else {
          this.$bvToast.toast("Estabelecimento já encontra-se nos Favoritos ", {
            title: "Atenção!",
            solid: true,
            variant: "danger",
            toaster: "b-toaster-top-full",
            appendToast: false,
            autoHideDelay: 2500,
          });
        }
      }
      localStorage.setItem("favoritos", JSON.stringify(favoritos));
    },
    verificaToken() {
      var dadosEmpresa = JSON.parse(localStorage.getItem("dadosEmpresa"));
      this.temaCor.CorBackgroundCabecalho = dadosEmpresa.CorBackgroundCabecalho;
      this.temaCor.CorItensCabecalho = dadosEmpresa.CorItensCabecalho;
    },
    corrigeMarca(imagem) {
      const marcaSplit = imagem.split('https://');
        if (marcaSplit.length >= 3) {
          return `https://${marcaSplit[2]}`;
      }
      return imagem
    },
  },
  mounted() {
    this.verificaToken();
  },
  components: {},
};
</script>
<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
</style>
<style
  lang="scss"
  scoped
>
.oferta {
  margin: -13px;
  .capa {
    width: 100%;
  }

  .icone {
    font-size: 20px;
    margin: 10px 10px;
  }

  .btn-star {
    float: right;
    margin-right: 10px;
    margin-top: -10px;
    padding: 0 15px;
  }
}
</style>
