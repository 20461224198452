<template>
  <div ref="conteudodash">
    <div
      v-if="carregando == true"
      class="carregando"
    >
      <img width="100" />
      <br />
      <br />
      <br />Carregando.. Aguarde
    </div>

    <div class="carroussel">
      <div v-if="destaques.length > 0">
        <carousel
          class=""
          :autoplay="true"
          :loop="true"
          :paginationEnabled="false"
          :perPageCustom="[[0, 3]]"
          :autoplayTimeout="5000"
          :autoplaySpeed="5000"
          :slideSpeed="1000"
          :value="valueCarousel"
          :singleItem="false"
          :scrollPerPage="true"
          :paginationPadding="1"
          paginationPosition="top-overlay"
          :resistanceCoef="0"
          easing="ease"
          paginationActiveColor="#000000"
          paginationColor="#fff"
        >
          <slide
            v-for="item in destaques"
            :key="item.id"
          >
            <div
              :class="
                item.Marca === 'https://clubecerto.com.br/images/parceiros/ponto_pontofrio.jpg'
                  ? 'menuBox backBlack'
                  : item.Marca === 'https://clubecerto.com.br/images/parceiros/clube-341.jpg'
                  ? 'menuBox backRoxo'
                  : item.Marca === 'https://clubecerto.com.br/images/parceiros/clubemagalu.jpg'
                  ? 'menuBox backBlue'
                  : item.Marca === 'https://clubecerto.com.br/images/parceiros/Raybanlogo.png'
                  ? 'menuBox backRed'
                  : item.Marca === 'https://clubecerto.com.br/images/parceiros/zeee.png'
                  ? 'menuBox backYellow'
                  : 'menuBox backWhite'
              "
              @click="openModalOferta(item)"
            >
              <img :src="linkMarcaDestaques(item.Marca)" />
              <div class="contInterno">
                <div
                  v-if="item.Beneficio"
                  class="beneficio"
                  :style="`background-color:${temaCor.CorBackgroundCabecalho};color:${temaCor.CorItensCabecalho}`"
                >
                  {{ item.Beneficio }}
                </div>
              </div>
            </div>
          </slide></carousel
        >
      </div>
    </div>

    <div
      style="display: flex; flex-direction: column"
      class="conteudoDash"
    >
      <div style="display: flex; align-items: center; padding: 0px 0px 0px 15px">
        <h4 style="width: 100%">Todas Ofertas</h4>
        <b-col
          cols="5"
          class="text-center"
          style="padding: 0; width: 120px"
        >
          <b-button
            class="btn-filtro"
            @click="modalFiltro = true"
            variant="dark"
          >
            <i class="fa fa-filter text-white mr-3"></i>Filtrar Por:
          </b-button>
        </b-col>
      </div>
      <b-row>
        <b-col>
          <div class="form">
            <b-form @submit.stop.prevent="novaBusca">
              <b-row>
                <b-col>
                  <div class="inputsMain">
                    <b-input
                      type="text"
                      v-model="form.palavraChave"
                      placeholder="Nome do Estabelecimento"
                    ></b-input>
                    <button>
                      <i
                        class="fa fa-search"
                        aria-hidden="true"
                      ></i>
                    </button></div
                ></b-col>
              </b-row>
            </b-form>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row
      class="mt-2"
      v-if="ofertas.length > 0"
    >
      <div
        :style="heightAssinatura"
        style="overflow: auto; margin-bottom: 38px; padding: 0px 10px"
        v-scroll="handleScroll"
      >
        <div
          ref="ofertas"
          class="px-3 d-md-flex flex-wrap justify-content-between"
        >
          <b-card
            v-for="item in ofertas"
            :key="item.id"
            no-body
            class="overflow-hidden mb-3 mx-2 offer-card col-md-4 col-lg-2"
            @click="openModalOferta(item)"
          >
            <b-row no-gutters>
              <b-col cols="3">
                <img
                  :src="item.Marca"
                  class="rounded-0"
                  width="100%"
                />
              </b-col>
              <b-col>
                <div class="contInterno">
                  <h4>{{ item.Nome }}</h4>
                  <span>{{ item.CategoriaNome }}</span>
                  <div
                    v-if="item.Beneficio"
                    class="beneficio beneficio-card"
                    :style="`background-color:${temaCor.CorBackgroundCabecalho};color:${temaCor.CorItensCabecalho}`"
                  >
                    {{ item.Beneficio }}
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
          <div
            class="text-white"
            v-observe-visibility="visibilityChanged"
          >
            fim
          </div>
          <div
            class="text-center"
            v-if="carregandoOfertas == true"
          >
            <i class="fa fa-solid fa-spinner fa-spin mr-3"></i> Carregando Ofertas
          </div>
        </div>
      </div>
    </b-row>
    <b-row
      v-else
      class="mt-4"
    >
      <b-col>
        <div
          v-if="enderecoCarregado == true && carregando == false"
          class="alert alert-danger"
        >
          Nenhum Estabelecimento Encontrado
        </div>
      </b-col>
    </b-row>

    <b-modal
      v-model="modalFiltro"
      hide-footer
      centered
      title="Filtro"
    >
      <div class="form">
        <b-form @submit.stop.prevent="novaBusca">
          <b-row>
            <b-col
              ><b-form-group
                id="input-group-1"
                label="Palavra-chave:"
                label-for="input-1"
                ><b-input
                  type="text"
                  v-model="form.palavraChave"
                ></b-input
              ></b-form-group>
              <b-form-group
                id="input-group-1"
                label="Categoria:"
                label-for="input-1"
                ><b-form-select
                  v-model="form.codigoCategoria"
                  :options="categorias"
                  class="mb-3"
                  value-field="Codigo"
                  text-field="Nome"
                >
                </b-form-select
              ></b-form-group>
              <b-form-group
                id="input-group-1"
                label="Estado:"
                label-for="input-1"
                ><b-form-select
                  v-model="form.codigoEstado"
                  :options="estados"
                  class="mb-3"
                  value-field="Codigo"
                  text-field="Estado"
                  @change="selecionaEstado()"
                >
                </b-form-select
              ></b-form-group>
              <b-form-group
                id="input-group-1"
                label="Cidade:"
                label-for="input-1"
              >
                <b-form-select
                  v-model="form.codigoCidade"
                  :options="cidadesAux"
                  class="mb-3"
                  value-field="Codigo"
                  text-field="Cidade"
                >
                </b-form-select
              ></b-form-group>
              <b-button
                type="submit"
                class="btn-block mt-3"
                :style="`background-color:${temaCor.CorBackgroundCabecalho};color:${temaCor.CorItensCabecalho}`"
                >Buscar</b-button
              >
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from "jsonwebtoken";
import VuePullRefresh from "vue-pull-refresh";
import { Carousel, Slide } from "vue-carousel";

import service from "../services";
export default {
  props: {
    estados: Array,
    cidades: Array,
    enderecoCarregado: Boolean,
    categorias: Array,
  },
  name: "DashBoard",
  watch: {
    cidades() {
      if (this.cidades.length > 0) this.abreCidades();
    },
    enderecoCarregado() {
      if (this.enderecoCarregado == true) {
        this.buscar();
        this.buscaDestaques();
      }
    },
  },
  data() {
    return {
      modalFiltro: false,
      styleConteudo: {
        marginTop: "150px",
        height: "100%",
      },
      heightAssinatura: { height: "375px" },
      temaCor: {},
      carregando: false,
      carregandoOfertas: true,
      form: {},
      cidadesAux: [],
      paginaAtual: 2,
      ofertas: [],
      busy: false,
      possuiOfertas: true,
      idEmpresa: null,
      width: { width: "0px" },
      menuSecundarioOpen: false,
      destaques: [],
      valueCarousel: 1,
      showHeader: true,
      showFooter: true,
    };
  },
  methods: {
    toBoolean(value) {
      if (value === "false" || value === "0") {
        return false;
      }
      return true;
    },
    handleScroll(e, position) {
      //console.log(this.$refs.ofertas.clientHeight - position.scrollTop);
      /*if (this.$refs.ofertas.clientHeight - position.scrollTop <= 300) {
        if (this.possuiOfertas == true && this.busy == false) {
          this.paginaAtual = this.paginaAtual + 1;
          this.buscar();
        }
      }*/
    },
    visibilityChanged(isVisible, entry) {
      //this.isVisible = isVisible
      if (isVisible == true) {
        if (this.possuiOfertas == true && this.busy == false) {
          this.paginaAtual = this.paginaAtual + 1;
          this.buscar();
        }
      }
      console.log(isVisible, entry);
    },
    corrigeMarca(item) {
      if (item.Marca) {
        const marcaSplit = item.Marca.split('https://');
        if (marcaSplit.length >= 2) {
          item.Marca = `https://${marcaSplit[1]}`;
        }
        return item;
      }
    },
    linkMarcaDestaques(item) {
        const marcaSplit = item.split('https://');
        if (marcaSplit.length >= 3) {
          item = `https://${marcaSplit[2]}`;
          return item;
        }
        return item;
    },
    openModalOferta(item) {
      const itemLogoCorrigida = this.corrigeMarca(item);
      this.$emit("OpenModalOferta", itemLogoCorrigida);
    },
    openDecoded() {
      var dadosEmpresa = JSON.parse(localStorage.getItem("dadosEmpresa"));
      this.temaCor.CorBackgroundCabecalho = dadosEmpresa.CorBackgroundCabecalho;
      this.temaCor.CorItensCabecalho = dadosEmpresa.CorItensCabecalho;
      var codigoEstado = localStorage.getItem("codigoEstado");
      if (codigoEstado) this.form.codigoEstado = codigoEstado;
    },
    novaBusca() {
      this.modalFiltro = false;
      this.paginaAtual = 1;
      this.buscar();
    },
    buscar() {
      this.busy = true;
      this.carregandoOfertas = true;

      var objSend = { ...this.form, dadosEmpresa: JSON.parse(localStorage.getItem("dadosEmpresa")) };
      objSend.pagina = this.paginaAtual;
      if (this.paginaAtual == 1) this.ofertas = [];

      service
        .post("Fornecedores", "Ofertas", objSend)
        .then((res) => {
          let newDestaques = [];
          this.possuiOfertas = true;
          this.carregandoOfertas = false;
          console.log(res.data);
          res.data.forEach((element) => {
            if (element.Ativo == 1) {
              const marca = element.Marca.split('https://');
              if (marca.length > 2) {
                element.Marca = `https://${marca[2]}`;
              }
              this.ofertas.push(element);
            }
            if (element.Ativo == 1 && element.Destaque == 1) {
              newDestaques.push(element);
            }
          });
          if (newDestaques.length > 0) {
            this.destaques = newDestaques;
            this.valueCarousel = 0;
          } else {
            this.valueCarousel = 1;
            if (this.destaques < 29) {
              this.buscaDestaques();
            }
          }
          this.busy = false;
        })
        .catch((e) => {
          this.carregandoOfertas = false;
          this.possuiOfertas = false;
          this.busy = false;
        });
    },
    abreCidades() {
      this.cidadesAux = [...this.cidades];
    },
    selecionaEstado() {
      if (this.form.codigoEstado) this.getCidades(this.form.codigoEstado);
    },
    getCidades(estado) {
      return new Promise((resolve, reject) => {
        this.carregando = true;
        var action = "Listar";
        service.post("Cidades", action, { codigoEstado: estado }).then((res) => {
          this.cidadesAux = res.data;
          this.cidadesAux.unshift({ Codigo: null, Cidade: "Cidade" });
          this.carregando = false;
          resolve(this.cidadesAux);
        });
      });
    },
    getSize() {
      console.log(this.heightAssinatura);
      this.heightAssinatura.height = `${screen.height - 355}px`;
      if (!this.toBoolean(localStorage.getItem("showFooter"))) this.heightAssinatura.height = `auto`;
      console.log("////////////////////", document.height, screen.height);
      console.log(this.heightAssinatura);
    },
    openModalOferta(item) {
      const itemLogoCorrigida = this.corrigeMarca(item);
      this.$emit("OpenModalOferta", itemLogoCorrigida);
    },
    returnIcone(icone) {
      return require("./../assets/icones/" + icone);
    },
    formataNome(nome) {
      var aux = "";
      if (nome) aux = nome.split(" ")[0];
      return aux;
    },

    buscaDestaques() {
      this.carregando = true;
      var objSend = {};
      objSend.codigoEstado = localStorage.getItem("codigoEstado");
      objSend.dadosEmpresa = JSON.parse(localStorage.getItem("dadosEmpresa"));
      service
        .post("Fornecedores", "Destaques", objSend)
        .then((res) => {
          this.carregando = false;
          res.data.forEach((element) => {
            if (element.Ativo == 1) this.destaques.push(element);
          });
          console.log(res.data);
        })
        .catch((e) => {
          this.carregando = false;
          this.$bvToast.toast(e, {
            title: "Atenção!",
            solid: true,
            variant: "danger",
            toaster: "b-toaster-top-full",
            appendToast: false,
            autoHideDelay: 2500,
          });
        });
    },
  },
  mounted() {
    this.abreCidades();
    this.openDecoded();
    this.getSize();
  },
  components: {
    VuePullRefresh,
    Slide,
    Carousel,
  },
};
</script>
<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style
  lang="scss"
  scoped
>
.conteudoDash {
  padding: 10px;
}
.btn-round {
  border-radius: 10px !important;
  height: auto;
  padding: 5px;
}
.btn-filtro {
  font-size: 12px;
  font-weight: normal;
  font-family: "Roboto";
  margin-bottom: 10px;
}
h3 {
  font-size: 18px;
  text-transform: uppercase;
}
h3 span {
  font-size: 12px;
  margin-left: 10px;
}
.boxConsultor .VueCarousel {
  height: 185px !important;
}

.contInterno {
  display: flex;
  flex-direction: column;
  h4 {
    margin: 10px;
    font-size: 14px;
    font-weight: bold;
  }

  span {
    margin: 10px 0 0 10px;
  }
  .beneficio {
    font-weight: bolder;
    font-family: "Roboto";
    font-weight: bold;
    padding: 5px;
    background: #333;
    color: #fff;
    margin-top: -5px;
    align-self: flex-end;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-bottomright: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .beneficio-card {
    margin-right: -15px;
  }
}

.card {
  border-radius: 10px !important;
  img {
    padding: 5px;
  }
}
.form-control,
.Password__field,
select.custom-select,
.vs__dropdown-toggle {
  border-radius: 0px !important;
}

.btn-copy-link {
  height: calc(1.5em + 0.75rem + 7px) !important;
  -webkit-border-top-right-radius: 14px !important;
  -webkit-border-bottom-right-radius: 14px !important;
  -moz-border-radius-topright: 14px !important;
  -moz-border-radius-bottomright: 14px !important;
  border-top-right-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
}
.inputsMain {
  display: flex;
  button {
    width: 90px;
    font-size: 23px;
    border: 1px solid #ced4da;
    border-left: none;
    box-shadow: 0;
    background: none;
  }
}
.carroussel {
  transition: 0.8;
}
.backBlack {
  background-color: #1b1b1b;
}
.backWhite {
  background-color: #ffffff;
}
.backRoxo {
  background-color: #5f2a7c;
}
.backBlue {
  background-color: #009eff;
}
.carroussel {
  background-color: #e9e9ea;
}
.backRed {
  background-color: #ed1d24;
}
.backYellow {
  background-color: #fecb02;
}
.menuBox {
  width: calc(100% - 18px);
  height: 100px;
  border-radius: 10px;
  margin: 10px;
  text-align: center;
  padding-top: 5px;
  img {
    max-height: 90px;
  }
  .contInterno {
    display: flex;
    flex-direction: column;
    .beneficio {
      font-weight: bolder;
      font-family: "Roboto";
      font-weight: bold;
      padding: 5px;
      background: #333;
      color: #fff;
      margin-top: -25px;
      align-self: flex-end;
      -webkit-border-top-left-radius: 10px;
      -webkit-border-bottom-right-radius: 10px;
      -moz-border-radius-topleft: 10px;
      -moz-border-radius-bottomright: 10px;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .d-md-flex .offer-card {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .px-md-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (max-width: 767px) {
  .offer-card {
    margin: 5px auto !important;
    flex: 0 0 100%;
  }
  .carroussel {
    padding: 0 7px;
  }
  .inputsMain input {
    width: 80%;
  }
}
</style>
